<template>
  <div v-loading="loadinga">
    <el-card>
      <el-alert title="CHMLFRP支付接口未完善，支付后积分将在12小时内到账。" :closable="false" type="warning" description="虚拟商品，充值后概不退款！"
        show-icon>
      </el-alert>
      <el-divider></el-divider>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充值3,000积分</span>
            </div>
            <span>3￥</span>
            <el-button type="primary" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充值6,000积分</span>
            </div>
            <span>6￥</span>
            <el-button type="primary" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充值9,000积分</span>
            </div>
            <span>9￥</span>
            <el-button type="primary" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充值11,000积分</span>
            </div>
            <span>10￥</span>
            <el-button type="primary" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充值33,000积分</span>
            </div>
            <span>30￥</span>
            <el-button type="primary" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充值66,000积分</span>
            </div>
            <span>60￥</span>
            <el-button type="primary" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充100给120,000积分</span>
            </div>
            <span>100￥</span>
            <el-button type="success" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充150给200,000积分</span>
            </div>
            <span>150￥</span>
            <el-button type="success" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :xl="6">
          <el-card shadow="hover">
            <div slot="header" class="clearfix">
              <span>充200给300,000积分</span>
            </div>
            <span>200￥</span>
            <el-button type="success" @click="open" round plain style="float: right; padding: 5px 20px">充值</el-button>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
    <el-dialog title="请扫描二维码" :visible.sync="dialogVisible" :before-close="handleClose">
      <el-alert title="支付时请备注你的用户名，支付多少金额将获得对应金额的积分。支付后积分将在12小时内到账。消费起步3元，不满3元的话充值的钱会被吃掉噢" type="warning"
        description="如果超过12小未到账，或忘记备注用户名，请联系242247494补发。" show-icon :closable="false">
      </el-alert>
      <el-divider></el-divider>
      <el-row :gutter="20">
        <el-card>
          <el-col :xs="24" :sm="12" :md="8">
            <span>支付宝</span>
            <el-image :src="zfb" :preview-src-list="srcList">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <span>微信</span>
            <el-image :src="wx" :preview-src-list="srcList">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <span>QQ</span>
            <el-image :src="qq" :preview-src-list="srcList">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </el-col>
        </el-card>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import { setToken, getToken, removeToken } from '@/utils/setToken.js';
import axios from 'axios';

export default {
  data() {
    return {
      loadinga: false,
      dialogVisible: false,
      wx: 'https://panel.chmlfrp.cn/img/微信.png',
      qq: 'https://panel.chmlfrp.cn/img/QQ.png',
      zfb: 'https://panel.chmlfrp.cn/img/支付宝.png',
      srcList: [
        'https://panel.chmlfrp.cn/img/wx.jpg',
        'https://panel.chmlfrp.cn/img/qq.png',
        'https://panel.chmlfrp.cn/img/zfb.jpg'
      ]
    }
  },
  created() {
    const usertoken = getToken('token');

    if (usertoken) {
      this.setData();
    } else {
      removeToken('token');
      this.$router.push('/login');
      this.$message({
        message: "无效的token，请重新登录",
        type: "error",
      });
    }

    this.$message({
      message: '请注意查看顶部警告信息！',
    });

  },
  methods: {
    setData(params) {
      this.loadinga = true;
      const usertoken = getToken('token');
      axios
        .get('https://cf-v1.uapis.cn/api/userinfo.php', {
          params: { usertoken, ...params },
        })
        .then(response => {
          if (response.data.error) {
            removeToken('token');
            this.$router.push('/login');
            this.$message({
              message: "无效的token，请重新登录",
              type: "error",
            });
          } else {
            const { userid, username, email, qq, usergroup, bandwidth, tunnel, realname, integral, userimg, term, tunnelstate } = response.data;

            setToken('userid', userid);
            setToken('username', username);
            setToken('email', email);
            setToken('qq', qq);
            setToken('usergroup', usergroup);
            setToken('bandwidth', bandwidth);
            setToken('tunnel', tunnel);
            setToken('realname', realname);
            setToken('integral', integral);
            setToken('userimg', userimg);
            setToken('term', term);
            setToken('tunnelstate', tunnelstate);
          }
        })
        .catch(error => {
          console.error('请求API时发生错误:', error);
        })
        .finally(() => {
          this.loadinga = false;
        })
    },

    open() {
      this.$confirm('请问您是要添加QQ(242247494)充值还是直接充值？', '提示-2', {
        confirmButtonText: '直接充值',
        cancelButtonText: '添加QQ充值',
        type: 'info'
      }).then(() => {
        this.open2()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '请联系QQ：242247494充值积分'
        });
      });
    },
    open2() {
      this.$confirm('支付时请备注你的用户名，支付多少金额将获得对应金额的积分。支付后积分将在12小时内到账。消费起步3元，不满3元的话充值的钱会被吃掉噢', '请注意！', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dialogVisible = true
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '充值已取消'
        });
      });
    },
    handleClose(done) {
      this.$confirm('确定关闭？')
        .then(() => {
          done();
        })
        .catch(() => { });
    },
  }
}
</script>
  
<style lang="less" scoped>
.el-col {
  margin-top: 16px;
}
</style>  